import {Link} from 'react-router-dom'
import { Facebook,Instagram,Youtube,Linkedin,EnvelopeAt, PersonGear,Whatsapp } from 'react-bootstrap-icons'
import img from '../asset/Ashwazep Logo_Artboard 2.png'
import img2 from '../asset/9k.png'

const FooterComponent=()=>{
    return(
<footer className="text-center text-lg-start bg-body-tertiary text-muted">
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    <div className="me-5 d-none d-lg-block">
      <span>सोशल नेटवर्क्सवर आमच्याशी कनेक्ट व्हा:</span>
    </div>
    <div>
      <Link to="https://www.facebook.com/profile.php?id=61565050739727&mibextid=ZbWKwL" className="me-4 text-reset">
        <Facebook color="royalblue" size={38}/>
      </Link>
      <Link to="https://www.instagram.com/ashwazep?igsh=MWpxcGs2Y3VnemQwZw==" className="me-4 text-reset">
      <Instagram color="#bc2a8d" size={38}/>
      </Link>
      <Link to="https://www.youtube.com/@ashwazep.online" className="me-4 text-reset" target='_blank'>
      <Youtube color="red" size={38}/>
      </Link>
      <Link to="https://wa.me/918421134990" className="me-4 text-reset" target='_blank'>
      <Whatsapp color="green" size={38}/>
      </Link>
      <Link to="https://ashwazep@gmail.com" className="me-4 text-reset">
      <EnvelopeAt color="#0077b5" size={38}/>
      </Link>
    </div>
  </section>
  <section className="">
    <div className="container-fluid text-center text-md-start mt-3">
      <div className="row mt-3">
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 logo">
          <h6 className="text-uppercase fw-bold mb-4">
            <img src={img}/>
          </h6>
          <h6 className="text-uppercase fw-bold mb-4 isg-logo">
            <img src={img2}/>
          </h6>
          {/* <ul className='list-unstyled'>
            <li><p>Contact Number: xxxxxxxxxx</p></li>   
            <li><p>Email Address: xxxx@xxx.com</p></li>
            </ul> */}
        </div>
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5 className="text-uppercase fw-bold mb-4">
           उत्पादने
          </h5>
          <p>
            <Link to="/commingSoon" className="text-reset">ऑनलाईन पुस्तके / ई - बुक</Link>
          </p>
          <p>
            <Link to="/commingSoon" className="text-reset">अभ्यासक्रम</Link>
          </p>
          
        </div>
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5 className="text-uppercase fw-bold mb-4">
            उपयुक्त लिंक
          </h5>
          <p>
            <Link to="/mahamandaleYojana" className="text-reset">महामंडळे व योजना</Link>
          </p>
          <p>
            <Link to="/districtWise" className="text-reset">जिल्ह्या विषयी</Link>
          </p>
          <p>
            <Link to="/coordinator" className="text-reset">समन्वयक</Link>
          </p>
          <p>
            <Link to="/websiteQuotation" className="text-reset">संकेतस्थळ / वेबसाईट </Link>
          </p>
        </div>
        <div id="contactUs" className="col-md-5 col-lg-5 col-xl-4 mx-auto mb-md-0 mb-4">
          <h5 className="text-uppercase fw-bold mb-4">संपर्क</h5>
          <p> अश्वझेप ट्रेनिंग अँड कन्सल्टन्सी सर्व्हिसेस प्रा. लि.<br/>
            कार्यालय क्र. १३५, पहिला मजला, ग्लोब बिझनेस पार्क,<br/>
            कल्याण – बदलापूर रोड, अंबरनाथ (पश्चिम),<br/>
            ठाणे - ४२१५०५
          </p>
          <div>
            <Link className='text-black' to="ashwazep@gmail.com">ashwazep@gmail.com</Link>
          </div>
          <div className='pt-3'><Link to="+918421134990" className='text-black'>+91-8421134990</Link></div>
        </div>
      </div>
    </div>
  </section>
  <div className="text-center p-4 bg-white" >
    © 2021 Copyright:
    <Link className="text-reset fw-bold" to="https://techloom.in/">TechLoom It Solution</Link>
  </div>
</footer>
    )
}

export default FooterComponent;