import {  useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import CardComponent from './CommonComponent/CardComponent'
import MahamandalYojnaShimmerComponent from './shimmerComponents/MahamandalYojnaShimmerComponent'
import { apiUrls } from '../util/apiUrl';
const DistrictWiseComponent=()=>{
    const [dropdownValue,setDropdownValue]=useState("विभाग निवडा")
    const [cardData,setCardData]=useState(null)
    const cardValue=[];


    const dropDowwnValueChange=async(eventKey,event)=>{
        event.preventDefault();
        event.persist();
        event.stopPropagation();
        setDropdownValue(event.currentTarget.innerText)
        const data=await fetch(apiUrls.districtWiseView,{
            method:"POST",
            body:JSON.stringify({
                region:event.currentTarget.innerText
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const json=await data.json();
        
        json.map((value,index)=>{
            cardValue.push({
                url:value.District_Url,
                title:value.District_Name,
                subTitle:value.District_Office,
                img:value.District_Image
            })
        })
        setCardData(cardValue)
        
    }
    return(
        <>
            <div className='text-center'>
                <div className='row'>
                    <div className='col-3'>
                        <Dropdown onSelect={dropDowwnValueChange}>
                            <label>विभाग &nbsp;</label>
                            <Dropdown.Toggle variant="danger" id="dropdown-basic" >
                                {dropdownValue}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item >कोकण</Dropdown.Item>
                                <Dropdown.Item >पुणे</Dropdown.Item>
                                <Dropdown.Item >नाशिक</Dropdown.Item>
                                <Dropdown.Item >औरंगाबाद</Dropdown.Item>
                                <Dropdown.Item >अमरावती</Dropdown.Item>
                                <Dropdown.Item >नागपूर</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                
                <div className='row'>
                    {
                      cardData==null?<MahamandalYojnaShimmerComponent/>:<CardComponent data={cardData} target={"_blank"}/>
                           
                    }
                    
                    
                </div>
            
            </div>
        </>
    )
}

export default DistrictWiseComponent;