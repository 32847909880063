

 let port="http://ashwazep.in:443"
// let port="http://localhost:3100"
export const apiUrls = {
    content:port+"/contentView",
    coordinatorInsert:port+"/coordinatorInsert",
    getDistrict:port+"/getDistrict",
    getDetailsOfCoordinator:port+"/getDetailsOfCoordinator",
    getTalukaFromDistrict:port+"/getTalukaFromDistrict",
    getVillageFromTaluka:port+"/getVillageFromTaluka",
    getPincodeFromDistrict:port+"/getPincodeFromDistrict",
    getPostOficeFromPinCode:port+"/getPostOficeFromPinCode",
    getGraminFromVillage:port+"/getGraminFromVillage",
    coordinatorWorkAreaInsert:port+"/coordinatorWorkAreaInsert",
    userInsert:port+"/userInsert",
    districtView:port+"/districtView",
    coordinatorView:port+"/coordinatorView",
    districtWiseView:port+"/districtWiseView",
    login:port+"/login",
    mahamandalYojna:port+"/mahamandalYojna",
    newsView:port+"/newsView",
    addNews:port+"/addNews",
    userCount:port+"/userCount",
    coordinatorCount:port+"/coordinatorCount",
    coordinatorViewInDashboard:port+"/coordinatorViewInDashboard",
    userViewInDashboard:port+"/userViewInDashboard",
    // getDetailsOfCoordinator:port+"/getDetailsOfCoordinator",
    getDetailsOfUser:port+"/getDetailsOfUser",
    getDetailsOfNews:port+"/getDetailsOfNews"
}