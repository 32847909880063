import {Link} from 'react-router-dom'
const contactUs=()=>{
    return( <>
        <div className="row">
            <div className="col-md-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.666808794919!2d73.19106667498085!3d19.209749882022635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be79382d5c359e7%3A0x527747279933691!2sGLOBE%20BUSINESS%20PARK!5e0!3m2!1sen!2sus!4v1727534484166!5m2!1sen!2sus"  className='map' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-md-6">
                <h5 className="text-uppercase fw-bold mb-4">Contact</h5>
                <p> Ashwazep Training and Consultancy Services Pvt. Ltd.<br/>
                    Office No. 135, 1st Floor, Globe Business Park,<br/>
                    Kalyan – Badlapur Road, Ambernath (West),<br/>
                    Thane – 421503
                </p>
                <div>
                    <Link className='text-black' to="ashwazep@gmail.com">ashwazep@gmail.com</Link>
                </div>
                <div className='pt-3'><Link to="+918421134990" className='text-black'>+91-8421134990</Link></div>
            </div>
        </div>
        
    </>)
}

export default contactUs