const WhatsNewShimmerComponent=()=>{
    return(
        <>
            <div class="link_div whatsnew h-50">
                <div class="link_div_heading d-flex justify-content-between">
                    <span>नवीन ?</span>
                    <button class="btn" data-bs-toggle="modal" data-bs-target="#myModalview1">सर्व पहा</button>
                </div>
            </div>
        </>
    )

}

export default WhatsNewShimmerComponent;