import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Outlet } from 'react-router-dom';
import MenuComponent from './components/MenuComponent';
import HeaderComponent from './components/HeaderComponent';
import WhatsNew from './components/WhatsNew'
import FooterComponent from './components/FooterComponent'
import StaticMenuComponent from './components/StaticMenuComponent'
import LoginState from './context/Login/LoginState';

function App() {

  return (
    <LoginState>
    <div className="App">
      <HeaderComponent/>
      <div className='row pt-10'>
        <div className='col-md-2'>
        <MenuComponent position="Left"/>
        </div>
        <div className='col-md-8'>
        <Outlet />
        </div>
        <div className='col-md-2'>
        <StaticMenuComponent/>
          <WhatsNew/>
        </div>
     
      </div>
      <FooterComponent/>

    </div>
    </LoginState>
  );
}


export default App;
