import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import {slideImages} from '../util/SliderImages'



  
const Slider=()=>{
    return(
        <div className="slide-container mb-5">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
                    <img src={slideImage.url}/>
            </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slider;