import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrls } from "../../util/apiUrl";
import { Link } from "react-router-dom"

const DashboardUserDetailComponent=()=>{
    const param=useParams()
    const [uDetails,setUDetails]=useState("")

    useEffect(()=>{fetchData()},[])

    const fetchData=async()=>{
        const data=await fetch(apiUrls.getDetailsOfUser,{
            method:'POST',
            body:JSON.stringify({"id":param.id}),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        }) 
        const json=await data.json()
        setUDetails(json[0])

    }

    const print=()=>{
        var content = document.getElementById("divcontents");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    return(
        <>
        <div id="divcontents">
            <div id="">
            <h2 className="text-center"> {uDetails.firstName+" "+uDetails.lastName}  </h2><br/><br/>
            <div className="row">
                
                <div className="col-md-4">
                    <p><strong>Name :</strong> {uDetails.firstName+" "+uDetails.fatherName+" "+uDetails.lastName}</p>
                    <p><strong>DOB : </strong>{uDetails.dob} </p>
                    <p><strong>Age : </strong>{uDetails.age} <label><strong>Gender :</strong> {uDetails.gender}</label></p>
                </div>
                <div className="col-md-4">
                <p><strong>Mobile Number : </strong>{uDetails.mobileNo} </p>
                    <p><strong>Whatsapp Number : </strong>{uDetails.whatsAppNo}</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6">
                    <p><strong>Current Address :</strong> {uDetails.addHouse+","+uDetails.addBulding+","+uDetails.addDistrict+","+uDetails.addTaluka+","+uDetails.addVillage+","+uDetails.addGram}</p>
                    <p><strong>Area :</strong>{uDetails.areaType}</p>
                    <p><strong>Pincode :</strong>{uDetails.addPincode}</p>
                </div>
                
            </div>
            </div>
        </div>
        <iframe id="ifmcontentstoprint" className="d-none"></iframe>
            <div class="text-center mt-5">
                <Link to="#" id="next" className="ashBtn" onClick={print}>छापा</Link>
            </div>
        </>
    )
}
const img={"width":"100%"}
export default DashboardUserDetailComponent;    