import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import CardComponent from './CommonComponent/CardComponent';
import MahamandalYojnaShimmerComponent from './shimmerComponents/MahamandalYojnaShimmerComponent';
import { apiUrls } from '../util/apiUrl';


const CoordinatorComponent =()=>{


    const [dropdownValue, setDropDownValue] = useState("विभाग निवडा")
    const [districtText, setDistrictText] = useState("जिल्हा निवडा")
    const [districtValues,setDistrictValues]=useState(null)
    const [coordinatorData,setCoordinatorData]=useState(null)
    const coordinatorTemp=[]

    const dropdownDistrictValueChange = async(eventKey,event) => {
        console.log(event.currentTarget.innerText)
        setDropDownValue(event.currentTarget.innerText)
        setDistrictText("select")
        const data =await fetch(apiUrls.districtView, { 
            method:"POST",
            body:JSON.stringify({
                region:event.currentTarget.innerText
            }),
            headers:{
                'content-type':'application/json; charset=UTF-8'
            }
        })
        const json=await data.json();
        setCoordinatorData(null)
        setDistrictValues(json)
    }

    const getCoordinatorData=async(eventkey,event)=>{
        setDistrictText(event.currentTarget.innerText)
        const data=await fetch(apiUrls.coordinatorView,{
            method:"POST",
            body:JSON.stringify({
                "districtName":event.currentTarget.innerText
            }),
            headers:{
                "content-type":"application/json; charset=UTF-8"
            }
        })
        const json=await data.json();
        json.map((value)=>{
            coordinatorTemp.push({
                title:value.Name,
                subTitle:value.DistrictName,
                url:"",
                email:value.Email,
                phone:value.Phone

            })
        })
        setCoordinatorData(json)

    }
   if(districtValues==null){return(<>
   <div className='row'>
        <div className='col-md-6 text-center'>
        <Dropdown onSelect={dropdownDistrictValueChange}>
            <label>विभाग :&nbsp;</label>
            <Dropdown.Toggle variant="danger" id="dropdown-basic" >
                {dropdownValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item >कोकण</Dropdown.Item>
                <Dropdown.Item >पुणे</Dropdown.Item>
                <Dropdown.Item >नाशिक</Dropdown.Item>
                <Dropdown.Item >औरंगाबाद</Dropdown.Item>
                <Dropdown.Item >अमरावती</Dropdown.Item>
                <Dropdown.Item >नागपूरी</Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>
        </div>
   </div>
   
   </>)}else{
    return (
        <>
        <div className='row'>
        <div className='col-md-6 text-center'>
            <Dropdown onSelect={dropdownDistrictValueChange}>
                <label>विभाग :&nbsp;</label>
                <Dropdown.Toggle variant="danger" id="dropdown-basic" >
                    {dropdownValue}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item >कोकण</Dropdown.Item>
                    <Dropdown.Item >पुणे</Dropdown.Item>
                    <Dropdown.Item >नाशिक</Dropdown.Item>
                    <Dropdown.Item >औरंगाबाद</Dropdown.Item>
                    <Dropdown.Item >अमरावती</Dropdown.Item>
                    <Dropdown.Item >नागपूरी</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        </div>
        <div className='col-md-6 text-center'>
        <Dropdown onSelect={getCoordinatorData}>
            <label>जिल्हा :&nbsp;</label>
            <Dropdown.Toggle variant="danger" id="dropdown-basic" >
                {districtText}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    districtValues.map((value,index)=>{
                        return(
                            <>
                                <Dropdown.Item >{value.District_Name}</Dropdown.Item>
                            </>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
        </div>
        </div>
        <div className='row'>
                {coordinatorData==null?<MahamandalYojnaShimmerComponent/>:<>
                    {coordinatorData.map((value)=>{
                        return(<>
                        <div className="col-4 mt-3">
                            <div className="card">
                                <img src={value.coPhoto} className="card-img-top coImg"
                                    alt="Hollywood Sign on The Hill" />
                                <div className="card-body">
                                    <h5 className="card-title text-center">{value.firstName+" "+value.fatherName+" "+value.lastName}</h5>
                                    <p className="card-title text-center">{value.mobileNo}</p>
                                    <p className="card-title text-center ">{value.email}</p>
                                </div>
                            </div>
                    </div></>)
                    })}
                </>}
        </div>
        
        
        </>
    )
   } 
}

export default CoordinatorComponent