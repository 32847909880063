import { useEffect, useState } from "react"
import TableComponent from "../CommonComponent/TabelComponent"
import { useContext } from "react"
import loginContext from '../../context/Login/LoginContext';
import { useNavigate } from "react-router-dom"
import { apiUrls } from "../../util/apiUrl";

const DashboardViewUser=()=>{
    const [tableData,setTableData]=useState(null)
    const role=useContext(loginContext)
    const navigate=useNavigate()

    useEffect(()=>{
        if(role.state.name!=""){fetchTableData()}
        else{
            navigate("/login")
        }
    },[])

    const fetchTableData=async()=>{
        const data=await fetch(apiUrls.userViewInDashboard)
        const json=await data.json();
        setTableData(json)
    }
    return(
        <>
            
            <div className="row mt-3">
            <TableComponent Data={tableData} from="user"/>
            </div>
        </>
    )
}

export default DashboardViewUser