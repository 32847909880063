import { useEffect, useState } from "react"
import {Data} from "../../util/Data"
import { NavLink,Link } from "react-router-dom"
import { Eye,PencilSquare,Trash3 } from 'react-bootstrap-icons'

const TableComponent=(props)=>{
    const [tableData,setTableData]=useState(null)
    const [redirection,setRedirection]=useState(null)

    useEffect(()=>{
        fetchData()
    })

    const fetchData=()=>{
        setTableData(props.Data)
        if(props.from=="coordinator"){setRedirection("/dashboardDetails")}else if(props.from=="user"){setRedirection("/dashboardDetailsUser")}

    }

    if(tableData!=null){
        return(
            <>
                <table border="1">
                    {
                        tableData.header.map((value)=>{
                            return(
                                <th>{value}</th>
                            )
                        })
    
                    }
                    {
                        tableData.body.map((value)=>{
                            return(
                            <tr key={value.id}>
                                {
                                    tableData.header.map((val,index)=>{
                                        return(<td>{value[val.replace(" ","")]}</td>)
                                    })
                                }
                                <td><Link to={redirection+"/"+value.id} className={props.from=="news"?"hidden":""}><Eye color="#dc3545" size={18}/></Link>&nbsp;
                                <Link to={props.from=="coordinator"?"/addCoordinator/"+value.id:"/addNews/"+value.News_Id} className={props.from=="user"?"hidden":""}><PencilSquare color="#dc3545" size={18}/></Link>&nbsp;
                                <Link to={redirection+"/"+value.id} ><Trash3 color="#dc3545" size={18}/></Link>
                                </td>
                            </tr>
                            )
                        })
                    }
                </table>
            </>
        )
    }
    
}

export default TableComponent