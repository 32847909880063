
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate,Link } from "react-router-dom";
import { useContext } from 'react';
import loginContext from '../context/Login/LoginContext';
import { apiUrls } from '../util/apiUrl';

const LoginCoponent=(props)=>{
    console.log(props)
    const navigate = useNavigate();
    const role=useContext(loginContext)
    const signUp=()=>{
        navigate("/addUser")
    }
    const loginUser= async(e)=>{
        e.preventDefault()
        const dataToSubmit={
            "userId":document.getElementById("userId").value,
            "passWord":document.getElementById("pass").value
        }

        const data=await fetch(apiUrls.login,{
            method:"POST",
            body:JSON.stringify(dataToSubmit),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        })

        const json=await data.json()
        if(json.login){
            role.update(json.userName)
            sessionStorage.setItem("user",json.userName)
            if(json.userName=="admin"){
            navigate("/dashboard")
            }else{
                navigate("/")  
            }
        }
        else{
            document.getElementById("userId").value="";
            document.getElementById("pass").value=""
            alert("Wrong user id password")
        }
    }

    return(
        <div className="row">
            <div className="offset-md-3 col-md-6 card p-5">
                <Form onSubmit={loginUser}>
                    <div className='row'>
                        <Form.Group className="mb-3" controlId="userId">
                            <Form.Label>User Id</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pass">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password"  />
                        </Form.Group>
                        <div className='row'>
                            <div className='col-md-6'>
                            <Button variant="danger" type="submit">
                                Login
                            </Button>
                            </div>
                            <div className='col-md-6'>
                            <Link className='btn btn-danger' onClick={signUp} type="button">
                                Sign Up
                            </Link>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        
    )
}

export default LoginCoponent;