import { useEffect, useState } from "react"
import WhatsNewShimmerComponent from "./shimmerComponents/WhatsNewShimmerComponent"
import { apiUrls } from "../util/apiUrl"

const WhatsNew=()=>{
    const [news,setNews]=useState(null)

    useEffect(()=>{
        fetchNews()
    },[])

    const fetchNews=async()=>{
        const data=await fetch(apiUrls.newsView);
        const json=await data.json();
        console.log(json)
        setNews(json.body)
    }

    if(news!=null){
        return(
            <div class="link_div whatsnew">
                    <div class="link_div_heading d-flex justify-content-between">
                            <span>नवीन ?</span>
                            <button class="btn" data-bs-toggle="modal" data-bs-target="#myModalview1">सर्व पहा</button>
                    </div>
                    <marquee behavior="scroll" direction="up" scrollamount="5" onmouseenter="this.stop();" onmouseleave="this.start();">
                        <div class="link_div_div">
                            {
                                news.map((value,index)=>{
                                    return(
                                        <>
                                            <span key={value.News_Id} className="d-block">
                                                <a href={value.News_URL} target="_blank">{value.News_Title}</a>
                                            </span><hr/>
                                        </>
                                    )
                                })
                            }
                            
                            
                        </div>
                    </marquee>
            </div>
        )
    }else{
        return(
            <WhatsNewShimmerComponent/>
        )
    }
    
}

export default WhatsNew