import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import TableComponent from "../CommonComponent/TabelComponent"
import { apiUrls } from "../../util/apiUrl"


const DashbordViewNews=()=>{
    const [tableData,setTableData]=useState(null)

    useEffect(()=>{
        fetchNews()
    },[])

    const fetchNews=async()=>{
        const data=await fetch(apiUrls.newsView);
        const json=await data.json();
        console.log(json)
        setTableData(json)
    }

    return(
        <>  
        <div className="row">
        <div className="text-end">
            <Link to="/addNews" className="btn btn-danger">Add News</Link>
        </div>
        </div>
        
        <div className="row mt-3">
        <TableComponent Data={tableData}  from="news"/>
        </div>
        
    </>
    )
}

export default DashbordViewNews;