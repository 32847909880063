import { useState } from "react"

const ApiCall=()=>{
    
    const [product,setProduct]=useState(null)
    
    const apiCall=async()=>{
        const randomNumber=parseInt(Math.random()*100)
        const data=await fetch(`https://dummyjson.com/products/${randomNumber}`)
        const jsonData=await data.json();
        setProduct(jsonData)
        
    }
    return(
        <div>
            <button onClick={apiCall}>Check api data</button>
            <table border="1" >
                {product!=null?<><tr>
                    <td>title</td>
                    <td>description</td>
                    <td>price</td>
                    <td>discount in Percentage</td>
                    <td>rating</td>
                    <td>stock</td>
                    <td>brand</td>
                    <td>thumbnail</td>
                </tr>
                <tr>
                    <td>{product.title}</td>
                    <td>{product.description}</td>
                    <td>{product.price}</td>
                    <td>{product.discountPercentage}</td>
                    <td>{product.rating}</td>
                    <td>{product.stock}</td>
                    <td>{product.brand}</td>
                    <td><img src={product.thumbnail}/></td>
                </tr></>:
                <tr><td>Click the button to load data</td></tr>}
            </table>
        </div>
    )

}

export default ApiCall;