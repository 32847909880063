
import { useState } from "react";
import LoginContext from "./LoginContext";

const LoginState=(props)=>{
    const s1={
        "name":sessionStorage.getItem("user")!=null?sessionStorage.getItem("user"):""
    }

    const [state,setState]=useState(s1)

    const update=(name)=>{
        setState({
            "name":name
        })
    }
    return(
        <LoginContext.Provider value={{state,update}}>
            {props.children}
        </LoginContext.Provider>
    )
}


export default LoginState