import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import loginContext from '../context/Login/LoginContext';
import Multiselect from 'multiselect-react-dropdown'
import { apiUrls } from '../util/apiUrl';

const AddCoordinatorWorkArea=()=>{
    const param=useParams()

    const [districtValues, setDistrictValues] = useState()
    const role=useContext(loginContext);
    const navigate = useNavigate()
    const data=[{country:"India",id:1},{country:"Indonesia",id:2},{country:"Pakistan",id:3}]
    const [option]=useState(data)
    const [pincode,setPincode]=useState()
    const [office,setOffice]=useState(false)
    const [vehical,setVehical]=useState(false)
    const [handicap,setHandicap]=useState(false)
    const [crimeReport,setCrimeReport]=useState(false)
    


    const [selectedPincode,setSelectedPincode]=useState()

    useEffect(()=>{
        if(role.state.name==""){
            navigate("/login")
        }else if(role.state.name!="admin"){
            navigate("/")
        }
        
        getDistrict()
    },[])

    const getDistrict=async()=>{
        const data=await fetch(apiUrls.getDistrict)
        const json=await data.json();
        console.log(json)
        setDistrictValues(json)
    }
    const getPincodeFromDistrict=async(e)=>{
        const district=e.target.value;
        const data=await fetch(apiUrls.getPincodeFromDistrict,{
            method:"POST",
            body:JSON.stringify({"district":district}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        setPincode(json)
        
        
        
    }
    const submitWorkArea=async(e)=>{
        e.preventDefault()
        const formdata={}
        formdata.cid=param.id
        formdata.district=document.getElementById("currentAddDistrict").value
        formdata.pincode=selectedPincode
        formdata.office=document.getElementById("office").value
        formdata.officeType=document.getElementById("officeType").value
        formdata.computerTrain=document.getElementById("computerTrain").value
        formdata.mobile=document.getElementById("mobile").value
        formdata.computer=document.getElementById("computer").value
        formdata.vehical=document.getElementById("vehical").value
        formdata.vehicalType=document.getElementById("vehicalType").value
        formdata.crimeReport=document.getElementById("crimeReport").value
        formdata.crimeReportType=document.getElementById("crimeReportType").value
        formdata.handicap=document.getElementById("handicap").value
        formdata.handicapType=document.getElementById("handicapType").value
        
        const data=await fetch(apiUrls.coordinatorWorkAreaInsert,{
            method:"POST",
            body:JSON.stringify({formdata}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(json.status=="success"){
            navigate("/viewCoordinator")
        }

    }
    const test=(selectedValue)=>{
        setSelectedPincode(selectedValue)
    }
    return(<>
        <Form onSubmit={submitWorkArea}>
        <div className='row'>
            <Form.Group className="mb-3 col-md-4" controlId="currentAddDistrict">
                <Form.Label>District/जिल्हा</Form.Label>
                <Form.Select aria-label="Default select example" onChange={getPincodeFromDistrict}>
                    {districtValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                        {
                            
                            districtValues.map((val,index)=>{
                                return(
                                    <option value={val.districtNameEng}>{val.districtNameMar}</option>
                                )
                            })
                        }
                    </>}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Multiselect options={pincode} value={selectedPincode} onSelect={test} displayValue="pincode" id='pincode'/>
            </Form.Group>
            
            </div>
            <table>
                <tr>
                    <td width="70%">स्वताचे कार्यालय आहे का ? </td>
                    <td>
                        <Form.Select aria-label="Default select example" id="office" onChange={e=>e.target.value=="होय"?setOffice(true):setOffice(false)}>
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">असल्यास कसे आहे? </td>
                    <td>
                        <Form.Select aria-label="Default select example" id="officeType" className={office?"":"disabled"} >
                            <option>-----------select-----------</option>
                            <option value="स्वतचे">स्वतचे  </option>
                            <option value="भाड्याचे">भाड्याचे  </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">संगणक प्रशिक्षण घेतलेले आहे का?  </td>
                    <td>
                    <Form.Select aria-label="Default select example"  id="computerTrain">
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">आधुनिक मोबाईल चा वापर करतात का?</td>
                    <td>
                    <Form.Select aria-label="Default select example"  id="mobile">
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">स्वताचा संगणक किंवा लॅपटॉप आहे का?</td>
                    <td>
                    <Form.Select aria-label="Default select example"   id="computer">
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">स्वताचे वाहन आहे का? </td>
                    <td>
                    <Form.Select aria-label="Default select example" id="vehical" onChange={e=>e.target.value=="होय"?setVehical(true):setVehical(false)}>
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">असल्यास कोणत्या प्रकारचे आहे? </td>
                    <td>
                    <Form.Select aria-label="Default select example" id="vehicalType" className={vehical?"":"disabled"} >
                            <option>-----------select-----------</option>
                            <option value="दुचाकी">दुचाकी  </option>
                            <option value="चारचाकी">चारचाकी  </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">कोणत्याही प्रकारचा गुन्हा दाखल दाखल आहे का?</td>
                    <td>
                    <Form.Select aria-label="Default select example" id="crimeReport" onChange={e=>e.target.value=="होय"?setCrimeReport(true):setCrimeReport(false)}>
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">असल्यास गुन्ह्याचा प्रकार व कलम नमूद करावे.</td>
                    <td>
                    <Form.Control type="text" id="crimeReportType" className={crimeReport?"":"disabled"} />
                    </td>
                </tr>
                <tr>
                    <td width="70%">कोणत्याही प्रकारचे अपंगत्व आहे का ?</td>
                    <td>
                    <Form.Select aria-label="Default select example" id="handicap" onChange={e=>e.target.value=="होय"?setHandicap(true):setHandicap(false)}>
                            <option>-----------select-----------</option>
                            <option value="होय">होय </option>
                            <option value="नाही">नाही </option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td width="70%">अपंगत्व असल्यास नमूद करावे.</td>
                    <td>
                    <Form.Control type="text" id="handicapType" className={handicap?"":"disabled"} />
                    </td>
                </tr>
            </table><br/>
            <div className='text-center'>
            <Button  variant="primary" type="submit">
                Submit
            </Button>
            </div>
            
        </Form>
    </>)
}

export default AddCoordinatorWorkArea;