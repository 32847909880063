
import { Check2Square } from 'react-bootstrap-icons'

const WebsiteQuotationComponent=()=>{
    return(
        <>
            <div className="row" id="websiteQuote">
                <div className="col-md-12 mb-3">
                    <h2 className='text-center'>Website Quotation For NGO</h2>
                </div>
                <div className="col-md-4">
                    <div className="card" >
                        <div className="card-header starter">
                            <h2>STARTER</h2>
                        </div>
                        <div className="card-body">
                            <ul className='list-unstyled'>
                                <li><Check2Square color="royalblue" size={25}/><span>Static</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>3 to 5 pages</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>Hosting 1 year</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>Domain 1 year</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>5 Email Id</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>Google Analytics(Website tracking)</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>1 month service support</span></li>
                                <li><Check2Square color="royalblue" size={25}/><span>Social media sharing option</span></li>
                            </ul>
                        </div>
                        <div class="card-footer  starter text-center">
                            ₹ 10,000/-
                        </div>
                        <span class="ribbon">₹ 10,000/-</span>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card" >
                        <div className="card-header standard">
                            <h2>STANDARD</h2>
                        </div>
                        <div className="card-body">
                            <ul className='list-unstyled'>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Dynamic</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>7 to 10 pages</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Hosting 1 year</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Domain 1 year</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>20 Email Id</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Google Analytics(Website tracking)</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>3 month service support</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Social media sharing option</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>SSL Certificate</span></li>
                                <li><Check2Square color="#ed1c24" size={25}/><span>Contact form with email service</span></li>
                            </ul>
                        </div>
                        <div class="card-footer  standard  text-center">
                                ₹ 12,000/-
                        </div>
                        <span class="ribbon">₹ 12,000/-</span>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card" >
                        <div className="card-header enhanced">
                            <h2>ENHANCED</h2>
                        </div>
                        <div className="card-body">
                            <ul className='list-unstyled'>
                                <li><Check2Square color="#15e487" size={25}/><span>Dynamic</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>12 to 15 pages</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Hosting 1 year</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Domain 1 year</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>50 Email Id</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Google Analytics(Website tracking)</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>6 month service support</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Social media sharing option</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Payment Gateway implimentation only</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Invoice Management</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>SSL Certificate</span></li>
                                <li><Check2Square color="#15e487" size={25}/><span>Database Backup Service 6 month</span></li>
                            </ul>
                        </div>
                        <div class="card-footer  enhanced  text-center text-white">
                            ₹ 20,000/-
                        </div>
                        <span class="ribbon">₹ 20,000/-</span>
                    </div>
                </div>
            </div>
        </>
    )

}

export default WebsiteQuotationComponent