import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const CardComponent=(props)=>{
    const comingData=props.data
    const target=props.target
    const [data,setData]=useState(comingData)
    
    useEffect(()=>{
        console.log("teast")
        setData(comingData)
    },)
    return(
        <>  
            {data.map((value)=>{
                return(
                    <>
                        <div className="col-4 mt-3">
                            <Link to={value.url} target={target}><div className="card">
                            <img src={value.img} className="card-img-top"
                                alt="Hollywood Sign on The Hill" />
                            <div className="card-body">
                                <h4 className="card-title text-center">{value.title}</h4>
                                <h5 className="card-title">{value.subTitle}</h5>
                                {/* <p class="card-text">
                                उद्योग संचालनालय राज्यामध्ये उद्योगांना प्रोत्साहित करण्याच्या दृष्टीकोनातून विविध योजनांची आखणी, अंमलबजावणी व सनियंत्रण करते. उद्योगांशी संबंधित विविध धोरणे आणि योजना उदा. औद्योगिक धोरण, विशेष आर्थिक क्षेत्र धोरण, माहिती व तंत्रज्ञान धोरण, सामूहिक प्रोत्साहन योजना इत्यादी तयार करणे व राबविण्याकरीता उद्योग संचालनालय राज्य शासनास सहाय्य करते.
                                </p> */}
                            </div>
                            </div>
                            </Link>
                        </div>
                    </>
                )
            })}
                        
                        
        </>
    )
}

export default CardComponent;