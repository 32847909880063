
import img1 from '../asset/1.jpg'
import img2 from '../asset/2.jpg'
import img3 from '../asset/3.jpg'
import img4 from '../asset/4.jpg'
import img5 from '../asset/5.jpg'
import img6 from '../asset/6.jpg'
import img7 from '../asset/7.jpg'
import img8 from '../asset/8.jpg'

export const TrainingImages = [
    {
        img:img1,
      url:"/commigsoon",
      title:"उद्योजकीय मानसिकता प्रशिक्षण"
    },
    {
        img: img2,
        url:"/commigsoon",
        title:"गृहनिर्माण सहकारी संस्था व्यवस्थापन प्रशिक्षण"
    },
    {
        img: img3,
        url:"/commigsoon",
        title:"नेतृत्व विकास प्रशिक्षण"
    },
    {
        img: img4,
        url:"/commigsoon",
        title:"व्यक्तिमत्व विकास प्रशिक्षण"
    },
    {
        img: img5,
        url:"/commigsoon",
        title:"सामाजिक संस्था व्यवस्थापन प्रशिक्षण"
    },
    {
        img: img6,
        url:"/commigsoon",
        title:"सामाजिक कार्यकर्ता प्रशिक्षण"
    },
    {
        img: img7,
        url:"/commigsoon",
        title:"उद्योग व्यवसाय प्रशिक्षण"
    },
    {
        img: img8,
        url:"/commigsoon",
        title:"रोगानुसार योगा प्रशिक्षण"
    },
  ];