import { Link,NavLink } from "react-router-dom"
import {Menu} from "../util/Menu"
import MenuShimmerComponent from "./shimmerComponents/MenuShimmerComponent"
import 'bootstrap/js/dist/collapse'
import { useEffect, useState } from "react"
const MenuComponent=(props)=>{
    const [menuData,setMenuData]=useState(null)
    const [collapseId,setCollapseId]=useState("")
    const [collapseIdChildren,setCollapseIdChildren]=useState("")
    const position=props.position;
    useEffect(()=>{
        fetchMenuData()
    },[])

    const fetchMenuData=async()=>{
        // const data=await fetch("http://localhost:3100/menuView")
        // const json=await data.json()
        setMenuData(Menu)
    }
    const collapsHandle=(e)=>{
       if(collapseId!=""){document.getElementById(collapseId).classList.remove("show");}
        
        setCollapseId(e.target.hash.replace("#",""))
    }
    const collapsChildrenHandle=(e)=>{
        if(collapseIdChildren!=""){document.getElementById(collapseIdChildren).classList.remove("show");}
         
        setCollapseIdChildren(e.target.hash.replace("#",""))
     }
    if(menuData!=null){
        return(
        
            <div className="sidebar">
                             <NavLink className="underline" to="/">मुख्यपृष्ठ</NavLink>
                            {menuData.map((value)=>{
                                if(value.Menu_Position===position){
                                    
                                        return(<>
                                            {value.children.length!==0?<Link to={"#"+value.Menu_Id} onClick={collapsHandle}  className="underline" key={value.Menu_Id} data-bs-toggle="collapse">{value.Menu_Name}</Link>:<NavLink  to={"/details/"+value.Menu_Id} className="underline" key={value.Menu_Id}>{value.Menu_Name}</NavLink >}
                                            <ul id={value.Menu_Id} className="nav collapse mx-3">
                                            {value.children.map((valueChildren)=>{
                                                return(<>
                                                        <li key={valueChildren.Menu_Id}>
                                                        {valueChildren.children.length!==0?<Link to={"#"+valueChildren.Menu_Id} onClick={collapsChildrenHandle} data-bs-toggle="collapse">{valueChildren.Menu_Name}</Link>: <NavLink to={"/details/"+valueChildren.Menu_Id} >{valueChildren.Menu_Name}</NavLink>}
                                                        </li>
                                                        {valueChildren.children.length!==0?<ul id={valueChildren.Menu_Id}  className="nav collapse mx-3">
                                                        {valueChildren.children.map((valueChildrensChild)=>{
                                                            return(
                                                            <li  key={valueChildrensChild.Menu_Id}>
                                                                <NavLink to={"/details/"+valueChildrensChild.Menu_Id}>{valueChildrensChild.Menu_Name}</NavLink>
                                                            </li>
                                                            )
                                                        })}
                                                        </ul>:<span></span>}
                                                    </>
                                                    )
                                            })}
                                            
                                            </ul>
                                            </>
                                        )

                                }
                            })
                            }
                            
                           
                          </div>
        )
    }else{
        return(
            <MenuShimmerComponent/>
        )
    }
    
}

export default MenuComponent