import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import MahamandalYojnaShimmerComponent from './shimmerComponents/MahamandalYojnaShimmerComponent'
import CardComponent from "./CommonComponent/CardComponent";
import { apiUrls } from "../util/apiUrl";


const MahamandaleYojanaComponent=()=>{
    const [yojnaData,setYojnaData]=useState(null)
    const [page,setPage]=useState(1)
    const cardValue=[];

    useEffect(()=>{
        fetchYojnaData()
    },[page])

    useEffect(()=>{
        window.addEventListener("scroll",handleScroll);
    },[])

    const fetchYojnaData=async()=>{
        const data=await fetch(apiUrls.mahamandalYojna,{
            method: 'POST',
            body: JSON.stringify({
                  "page":page
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const json=await data.json();
        json.map((value,index)=>{
            cardValue.push({
                url:value.Yojana_Url,
                title:value.Yojana_Title,
                subTitle:"",
                img:value.Yojana_Img
            })
        })
        setYojnaData(cardValue)
    }
    const handleScroll=()=>{
        try{
            if(document.documentElement.scrollHeight-window.innerHeight<=document.documentElement.scrollTop+(200*page)){
                
                setPage((prev)=> prev+1 )
            }
        }catch(e){
            console.log(e)
        }
    }

    if(yojnaData!=null){return(
        <>
            <div class="row row-cols-1 row-cols-md-3 g-4 yojna">
                <CardComponent data={yojnaData} target={"_blank"}/>
                
                </div>
        </>
    )}else{
        return(
            <><MahamandalYojnaShimmerComponent/></>
        )
    }
}

export default MahamandaleYojanaComponent;