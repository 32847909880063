import Slider from "./Slider"
// import LinkDiv from './LinkDiv'
import CardComponent from "./CommonComponent/CardComponent"
import { useState } from "react"
import { TrainingImages } from "../util/TrainingImages"

const LandingComponent=()=>{
    console.log(TrainingImages)
    const [data]=useState(TrainingImages)
    return(
    <div>
        <Slider/>
        {/* <LinkDiv/> */}
        
        <div className="row mt-3">
            <h2 className="text-center">आगामी अभ्यासक्रम</h2>
            <CardComponent data={data} target={"_self"}/>
        </div>
    </div>
    )
}

export default LandingComponent