import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

import { apiUrls } from '../util/apiUrl';

const AddUserComponent=()=>{

    

    const [cityHideShow,setCityHideShow]=useState(false)
    const [villageHideShow,setVillageHideShow]=useState(true)   
    const [startDate, setStartDate] = useState(new Date());
    const [age, setAge] = useState(null);


    /*drop down values */
    
    const [districtValues,setDistrictValues]=useState(null)
    const [talukaValues,setTalukaValues]=useState(null)
    const [pinCodeValues,setPinCodeValues]=useState(null)
    const [villageValues,setVillageValues]=useState(null)
    const [postOfficeValues,setPostOfficeValues]=useState(null)
    const [gramin,setGramin]=useState(null)
   

    useEffect(()=>{
        
        getDistrict()
    },[])

    const getDistrict=async()=>{
        const data=await fetch(apiUrls.getDistrict)
        const json=await data.json();
        console.log(json)
        setDistrictValues(json)
    }
    

    const submitUser=async(e)=>{
        e.preventDefault()
        const submitData={
            firstName: document.getElementById("firstName").value,
            fatherName: document.getElementById("fatherName").value,
            lastName: document.getElementById("lastName").value,
            dob: document.getElementById("dob").value,
            age: document.getElementById("age").value,
            gender: document.getElementById("gender").value,
            education: document.getElementById("education").value,
            currentAreaType: document.getElementById("currentAreaType").value,
            currentAreaType1: cityHideShow?document.getElementById("currentAreaType1").value:"NA",
            currentAddHouse: document.getElementById("currentAddHouse").value,
            currentAddBulding: document.getElementById("currentAddBulding").value,
            currentAddDistrict: document.getElementById("currentAddDistrict").value,
            currentAddTaluka: document.getElementById("currentAddTaluka").value,
            currentAddVillage: villageHideShow?"NA":document.getElementById("currentAddVillage").value,
            currentAddGram: villageHideShow?"NA":document.getElementById("currentAddGram").value,
            currentAddPin: document.getElementById("currentAddPin").value,
            // currentAddPost: document.getElementById("currentAddPost").value,
            mobileNo: document.getElementById("mobileNo").value,
            whatsAppNo: document.getElementById("whatsAppNo").value,
            email: document.getElementById("email").value
        }

        let formData = new FormData()
        //formData.append('data',JSON.stringify(submitData))
        // formData.append( JSON.stringify(submitData))

        axios.post(apiUrls.userInsert,submitData)
        .then(res=>{console.log(res)})
        .catch(err=>console.log(err))


    //     const data=await fetch("http://localhost:3100/upload",{
    //         method:"POST",
    //         body:JSON.stringify(formData),
    //         headers:{
    //             "content-type":"application/json; charser=utf-8"
    //         }
    //     })

    //     const json=await data.json();
        
     }

    /*fetch data for dropdown */

    const getTaluka=async(e)=>{
        const district=e.target.value;
        const data=await fetch(apiUrls.getTalukaFromDistrict,{
            method:"POST",
            body:JSON.stringify({"district":district}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        setTalukaValues(json)
        getPincodeFromDistrict(e)
        
    }
    const getPincodeFromDistrict=async(e)=>{
        const district=e.target.value;
        const data=await fetch(apiUrls.getPincodeFromDistrict,{
            method:"POST",
            body:JSON.stringify({"district":district}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        setPinCodeValues(json)
        
    }
    const getPinCodeAndVillage=async(e)=>{
        const taluka=e.target.value;
        const data=await fetch(apiUrls.getVillageFromTaluka,{
            method:"POST",
            body:JSON.stringify({"taluka":taluka}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        //setPinCodeValues(json)
        setVillageValues(json)
        
        
    }

    const getPostOffice=async(e)=>{
        const pincode=e.target.value;
        const data=await fetch(apiUrls.getPostOficeFromPinCode,{
            method:"POST",
            body:JSON.stringify({"pincode":pincode}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        setPostOfficeValues(json)        
    }

    const getGramin=async(e)=>{
        const village=e.target.value;
        const data=await fetch(apiUrls.getGraminFromVillage,{
            method:"POST",
            body:JSON.stringify({"village":village}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        setGramin(json[0].gramPanchayatNameMar) 
        
    }

    /*hide and show*/
    const birthdayChange=(date)=>{
        setStartDate(date)
        var ageDifMs = Date.now() - date;
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
            
        const age=Math.abs(ageDate.getUTCFullYear() - 1970);
        setAge(age)
    }
    const areaOnSelect=(e)=>{
        if(e.target.value==="शहरी"){setCityHideShow(true)
        setVillageHideShow(true)}
        else if(e.target.value==="ग्रामीण"){setCityHideShow(false)
            setVillageHideShow(false)}
        else{
            setCityHideShow(false)
        setVillageHideShow(true)
        }
    }
    

      return(
        <>
            <Form onSubmit={submitUser}>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-4" controlId="firstName">
                        <Form.Label>Name/नाव</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4" controlId="fatherName">
                        <Form.Label>Father Name/वडिलांचे नाव </Form.Label>
                        <Form.Control type="text"  />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-4" controlId="lastName">
                        <Form.Label>Surname/आडनाव</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-3" controlId="dob">
                        <Form.Label>Date of Birth/जन्म तारीख </Form.Label>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            isClearable
                            onChange={birthdayChange}
                            id="dob"
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={115}
                            scrollableYearDropdown={true}
                            scrollableMonthDropdown
                            minDate={new Date("01/01/1902")}
                            maxDate={new Date()} 
                            />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="age">
                        <Form.Label>Age/वय</Form.Label>
                        <Form.Control type="text" value={age} disabled/>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3" controlId="gender">
                        <Form.Label>Sex/लिंग </Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>-----------select-----------</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </Form.Select>
                    </Form.Group>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="education">
                        <Form.Label>Education/शिक्षण</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                </div>
                <div className='row'>
                <hr/>
                <Form.Label><strong>Current Address सध्याचा पत्ता</strong></Form.Label>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-3" controlId="currentAreaType" >
                        <Form.Label>Area/क्षेत्र </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={areaOnSelect}>
                            <option>-----------select-----------</option>
                            <option value="ग्रामीण">Rural/ग्रामीण </option>
                            <option value="शहरी">Urban/शहरी</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={cityHideShow?"mb-3 col-md-3 d-bock":"mb-3 col-md-3 d-none"} controlId="currentAreaType1">
                        <Form.Label>शहरी/City </Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>-----------select-----------</option>
                            <option value="नगरपंचायत">नगरपंचायत  </option>
                            <option value="नगरपरिषद">नगरपरिषद </option>
                            <option value="महानगर पालिका">महानगर पालिका </option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3" controlId="currentAddHouse">
                        <Form.Label>House No./घर क्रमांक</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="currentAddBulding">
                        <Form.Label>Building No./इमारत नाव,  </Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-3" controlId="currentAddDistrict">
                        <Form.Label>District/जिल्हा</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getTaluka}>
                            {districtValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    districtValues.map((val,index)=>{
                                        return(
                                            <option value={val.districtNameEng}>{val.districtNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="currentAddTaluka">
                        <Form.Label>Taluka/तालुका </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPinCodeAndVillage}>
                        {talukaValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    talukaValues.map((val,index)=>{
                                        return(
                                            <option value={val.blockNameMar}>{val.blockNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="currentAddPin">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPostOffice}>
                        {pinCodeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    pinCodeValues.map((val,index)=>{
                                        return(
                                            <option value={val.pincode}>{val.pincode}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3 col-md-3" controlId="currentAddPost">
                        <Form.Label>post office/पोस्ट ऑफिस</Form.Label>
                        <Form.Select aria-label="Default select example">
                        {postOfficeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    postOfficeValues.map((val,index)=>{
                                        return(
                                            <option value={val.postOfficeName}>{val.postOfficeName}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group> */}
                   
                </div>
                <div className='row'>
                   
                <Form.Group className={villageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="currentAddVillage">
                        <Form.Label>City / Village  शहर </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getGramin}>
                        {villageValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    villageValues.map((val,index)=>{
                                        return(
                                            <option value={val.villageNameEng}>{val.villageNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={villageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="currentAddGram">
                        <Form.Label>ग्रामीण</Form.Label>
                        <Form.Control type="text" disabled value={gramin}/>
                    </Form.Group>
                </div>
                
                <div className='row'>
                    <hr/>
                    <Form.Label><strong>Contact/संपर्क</strong></Form.Label>
                </div>
                <div className='row'>
                <Form.Group className="mb-3 col-md-3" controlId="mobileNo">
                        <Form.Label>Mobile/मोबाईल</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="whatsAppNo">
                        <Form.Label>Whatsapp/व्हॉट्सॲप</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="email">
                        <Form.Label>Email Id/इमेल आयडी </Form.Label>
                        <Form.Control type="email" />
                    </Form.Group>
                </div>
                
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </>
    )
}

export default AddUserComponent