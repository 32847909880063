import { useEffect, useState } from "react"
import TableComponent from "../CommonComponent/TabelComponent"
import axios from "axios"
import { Link } from "react-router-dom"
import { useContext } from "react"
import loginContext from '../../context/Login/LoginContext';
import { useNavigate } from "react-router-dom"
import { apiUrls } from "../../util/apiUrl"


const DashboardViewCoordinator=()=>{
    const [tableData,setTableData]=useState(null)
    const role=useContext(loginContext)
    const navigate=useNavigate()

    useEffect(()=>{
        if(role.state.name!=""){fetchTableData()}
        else{
            navigate("/login")
        }
    },[])

    const fetchTableData=async()=>{
        const data=await fetch(apiUrls.coordinatorViewInDashboard)
        const json=await data.json();
        setTableData(json)
    }
   if(role.state.name=="admin"){
    return(
        <>  
            <div className="row">
            <div className="text-end">
                <Link to="/addCoordinator" className="btn btn-danger">Add Coordinator</Link>
            </div>
            </div>
            
            <div className="row mt-3">
            <TableComponent Data={tableData}  from="coordinator"/>
            </div>
            
        </>
    )
   } 
}

export default DashboardViewCoordinator