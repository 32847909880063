
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useParams,useNavigate } from 'react-router-dom';
import { apiUrls } from '../../util/apiUrl';
import { useEffect, useState } from 'react';

const DashboardAddNews=()=>{
    const param=useParams()
    const navigate=useNavigate()
    const [newsValues,setNewsValues]=useState()
    useEffect(()=>{
        if(param.id!="" && param.id!=undefined){
            getValueToUpdate()
        }
    },[])
    const getValueToUpdate=async()=>{
        const data=await fetch(apiUrls.getDetailsOfNews,{
            method:'POST',
            body:JSON.stringify({"id":param.id}),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        }) 
        const json=await data.json()
        setNewsValues(json[0])
        document.getElementById("newsTitle").value=json[0].News_Title
        document.getElementById("newsURL").value=json[0].News_URL
    }
    const submitNews=(e)=>{
        e.preventDefault()
        let formData={}
        formData.newsTitle=document.getElementById("newsTitle").value
        formData.newsURL=document.getElementById("newsURL").value
        formData.id=param.id
        console.log(formData)
        axios.post(apiUrls.addNews,formData)
        .then(res=>{
            if(res.data.status=="success"){
                navigate("/viewNews")
            }
        })
        .catch(err=>console.log(err))
    }

    return(<>
    <Form onSubmit={submitNews}>
    <div className='row'>
        <Form.Group className="mb-3 col-md-12" controlId="newsTitle">
            <Form.Label>News Title</Form.Label>
            <Form.Control type="text" />
        </Form.Group>
    </div>
    <div className='row'>
        <Form.Group className="mb-3 col-md-12" controlId="newsURL">
            <Form.Label>News Url</Form.Label>
            <Form.Control type="text" />
        </Form.Group>
    </div>
        <Button variant="primary" type="submit">
                    Submit
        </Button>
    </Form>
    
    </>)
}

export default DashboardAddNews