
import { NavLink } from "react-router-dom"
import { useContext } from "react"
import loginContext from "../context/Login/LoginContext"


const StaticMenuComponent=()=>{
    const role=useContext(loginContext)
    return(
        <>
            <div class="sidebar">
                <NavLink className="underline" to="/">मुख्यपृष्ठ</NavLink>
                <NavLink className="underline" to="/mahamandaleYojana">महामंडळे व योजना</NavLink >
                <NavLink className="underline" to="/districtWise">जिल्ह्या विषयी</NavLink >
                <NavLink className="underline" to="/commigsoon" >ऑनलाईन पुस्तके / ई - बुक</NavLink>
                <NavLink className="underline" to="/websiteQuotation" >संकेतस्थळ / वेबसाईट </NavLink>
                <NavLink className="underline" to="/coordinator">समन्वयक</NavLink >
                {role.state.name=="admin" || role.state.name=="Coordinator"?<NavLink  to="/dashboard">डॅशबोर्ड</NavLink >:<></>}
            </div>
        </>
    )
}

export default StaticMenuComponent