
import img1 from '../asset/fullimage1.jpg'
import img2 from '../asset/fullimage2.jpeg'
import img3 from '../asset/fullimage3.jpg'
import img4 from '../asset/fullimage4.jpg'

export const slideImages = [
    {
      url:img1,
    },
    {
      url: img2,
    },
    {
      url: img3,
    },
    {
      url: img4,
    },
  ];