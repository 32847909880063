import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import loginContext from '../context/Login/LoginContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../util/apiUrl';

const AddCoordinatorComponent=()=>{
    const param=useParams()

    const role=useContext(loginContext);
    const navigate=useNavigate()
    const [permanentAddress,setPermanentAddress]=useState(true)
    const [cityHideShow,setCityHideShow]=useState(false)
    const [villageHideShow,setVillageHideShow]=useState(true)   
    const [startDate, setStartDate] = useState(new Date());
    const [age, setAge] = useState(null);
    const [aadharImageUpload,setAadharImageUpload]=useState(null)
    const [coPhotoUpload,setCoPhotoUpload]=useState(null)


    const [perVillageHideShow,setPerVillageHideShow]=useState(true)
    const [perCityHideShow,setPerCityHideShow]=useState(false)

    /*drop down values */
    
    const [districtValues,setDistrictValues]=useState(null)
    const [talukaValues,setTalukaValues]=useState(null)
    const [pinCodeValues,setPinCodeValues]=useState(null)
    const [villageValues,setVillageValues]=useState(null)
    const [postOfficeValues,setPostOfficeValues]=useState(null)
    const [gramin,setGramin]=useState(null)
    const [isEdit,setIsEdit]=useState(false)

    const [perTalukaValues,setPerTalukaValues]=useState(null)
    const [perPinCodeValues,setperPinCodeValues]=useState(null)
    const [perVillageValues,setperVillageValues]=useState(null)
    const [perPostOfficeValues,setperPostOfficeValues]=useState(null)
    const [perGramin,setPerGramin]=useState(null)
    const [coordinatrValues,setCoordinatorValues]=useState()

    useEffect(()=>{
        if(role.state.name==""){
            navigate("/login")
        }else if(role.state.name!="admin"){
            navigate("/")
        }
        
        getDistrict()
        if(param.id!="" && param.id!=undefined){
            setIsEdit(true)
            getValueToUpdate()
        }
    },[])

    const getDistrict=async()=>{
        const data=await fetch(apiUrls.getDistrict)
        const json=await data.json();
        console.log(json)
        setDistrictValues(json)
    }
    const getValueToUpdate=async()=>{
        const data=await fetch(apiUrls.getDetailsOfCoordinator,{
            method:'POST',
            body:JSON.stringify({"id":param.id}),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        }) 
        const json=await data.json()
        setCoordinatorValues(json[0])
            
            document.getElementById("firstName").value=json[0].firstName
            document.getElementById("fatherName").value=json[0].fatherName
            document.getElementById("lastName").value=json[0].lastName
            document.getElementById("gender").value=json[0].gender
            document.getElementById("age").value=json[0].age
            document.getElementById("dob").value=""
            setStartDate(json[0].dob)
            document.getElementById("education").value=json[0].education
            document.getElementById("currentAreaType").value=json[0].currentAreaType
            document.getElementById("currentAddHouse").value=json[0].currentAddHouse
            document.getElementById("currentAddBulding").value=json[0].currentAddBulding
            document.getElementById("currentAddDistrictEdit").value=json[0].currentAddDistrict
            document.getElementById("currentAddTalukaEdit").value=json[0].currentAddTaluka
            document.getElementById("currentAddPinEdit").value=json[0].currentAddPin

            document.getElementById("mobileNo").value=json[0].mobileNo
            document.getElementById("whatsAppNo").value=json[0].whatsAppNo
            document.getElementById("email").value=json[0].email
            
    }
    const submitCoordinator=async(e)=>{
        e.preventDefault()
        var skillsSelect = document.getElementById("currentAddDistrict");
        var selectedCurrentAddDistrict = skillsSelect.options[skillsSelect.selectedIndex].text;
        const submitData={
            firstName: document.getElementById("firstName").value,
            fatherName: document.getElementById("fatherName").value,
            lastName: document.getElementById("lastName").value,
            dob: document.getElementById("dob").value,
            age: document.getElementById("age").value,
            gender: document.getElementById("gender").value,
            education: document.getElementById("education").value,
            currentAreaType: document.getElementById("currentAreaType").value,
            currentAreaType1: cityHideShow?document.getElementById("currentAreaType1").value:"NA",
            currentAddHouse: document.getElementById("currentAddHouse").value,
            currentAddBulding: document.getElementById("currentAddBulding").value,
            currentAddDistrict: selectedCurrentAddDistrict,
            currentAddTaluka: document.getElementById("currentAddTaluka").value,
            currentAddVillage: document.getElementById("currentAddVillage").value,
            currentAddGram: villageHideShow?"NA":document.getElementById("currentAddGram").value,
            currentAddPin: document.getElementById("currentAddPin").value,
            currentAddPost: "NA",
            permanentAreaType: permanentAddress?document.getElementById("currentAreaType").value:document.getElementById("permanentAreaType").value,
            permanentAreaType1: permanentAddress?(cityHideShow?document.getElementById("currentAreaType1").value:"NA"):(perCityHideShow?document.getElementById("permanentAreaType1").value:"NA"),
            permanentAddHouse: permanentAddress?document.getElementById("currentAddHouse").value:document.getElementById("permanentAddHouse").value,
            permanentAddBuilding: permanentAddress?document.getElementById("currentAddBulding").value:document.getElementById("permanentAddBuilding").value,
            permanentAddDistrict: permanentAddress?document.getElementById("currentAddDistrict").value:document.getElementById("permanentAddDistrict").value,
            permanentAddTaluka: permanentAddress?document.getElementById("currentAddTaluka").value:document.getElementById("permanentAddTaluka").value,
            permanentAddVillage: permanentAddress?document.getElementById("currentAddVillage").value:(perVillageHideShow?"NA":document.getElementById("permanentAddVillage").value),
            permanentAddGram: permanentAddress?document.getElementById("currentAddGram").value:(perVillageHideShow?"NA":document.getElementById("permanentAddGram").value),
            permanentAddPin: permanentAddress?document.getElementById("currentAddPin").value:document.getElementById("permanentAddPin").value,
            permanentAddPost: "NA",
            currentBusiness: document.getElementById("currentBusiness").value,
            officeAddress: document.getElementById("officeAddress").value,
            mobileNo: document.getElementById("mobileNo").value,
            whatsAppNo: document.getElementById("whatsAppNo").value,
            email: document.getElementById("email").value,
            coPhotoName: "",
            aadharNo: document.getElementById("aadharNo").value,
            aadharPhotoName: "",
            workAreaId: "w2",
            cid:param.id,
            permanentAddressCheck:document.getElementById("custom-switch").value
        }

        let formData = new FormData()
        
        if(!isEdit){
            formData.append('files', aadharImageUpload.data)
        formData.append('files', coPhotoUpload.data)
        }
        formData.append('data',JSON.stringify(submitData))
        
        // formData.append( JSON.stringify(submitData))

        axios.post(apiUrls.coordinatorInsert,formData)
        .then(res=>{
            console.log(res.data.status)
            if(res.data.status=="success" && !isEdit){
                navigate("/coordinatorWorkArea/"+res.data.data[0].cid)
            }else{
                navigate("/viewCoordinator")
            }
        })
        .catch(err=>console.log(err))


    //     const data=await fetch("http://localhost:3100/upload",{
    //         method:"POST",
    //         body:JSON.stringify(formData),
    //         headers:{
    //             "content-type":"application/json; charser=utf-8"
    //         }
    //     })

    //     const json=await data.json();
        
     }

    /*fetch data for dropdown */

    const getTaluka=async(e)=>{
        const district=e.target.value;
        const data=await fetch(apiUrls.getTalukaFromDistrict,{
            method:"POST",
            body:JSON.stringify({"district":district}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(e.target.id == 'currentAddDistrict'){ setTalukaValues(json) }
        else { setPerTalukaValues(json) }
        getPincodeFromDistrict(e)
    }

    const getPinCodeAndVillage=async(e)=>{
        const taluka=e.target.value;
        const data=await fetch(apiUrls.getVillageFromTaluka,{
            method:"POST",
            body:JSON.stringify({"taluka":taluka}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(e.target.id == 'currentAddTaluka'){
            //setPinCodeValues(json)
            setVillageValues(json)
        } else {
           // setperPinCodeValues(json)
            setperVillageValues(json)
        }
        
        
    }

    const getPincodeFromDistrict=async(e)=>{
        const district=e.target.value;
        const data=await fetch(apiUrls.getPincodeFromDistrict,{
            method:"POST",
            body:JSON.stringify({"district":district}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(e.target.id === 'permanentAddDistrict'){
            setperPinCodeValues(json)
        }else{
            setPinCodeValues(json)
        }
        
        
        
    }
    const getPostOffice=async(e)=>{
        const pincode=e.target.value;
        const data=await fetch(apiUrls.getPostOficeFromPinCode,{
            method:"POST",
            body:JSON.stringify({"pincode":pincode}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(e.target.id == 'currentAddPin'){ setPostOfficeValues(json) }
        else { setperPostOfficeValues(json) }
        
    }

    const getGramin=async(e)=>{
        const village=e.target.value;
        const data=await fetch(apiUrls.getGraminFromVillage,{
            method:"POST",
            body:JSON.stringify({"village":village}),
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        })
        const json=await data.json();
        if(e.target.id == 'currentAddVillage'){ setGramin(json[0].gramPanchayatNameMar) }
        else { setPerGramin(json[0].gramPanchayatNameMar) }
        
    }

    /*hide and show*/
    const permanentAddressOnChange=()=>{
        if(permanentAddress){setPermanentAddress(false)}
        else{setPermanentAddress(true)}
    }
    const birthdayChange=(date)=>{
        setStartDate(date)
        var ageDifMs = Date.now() - date;
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
            
        const age=Math.abs(ageDate.getUTCFullYear() - 1970);
        setAge(age)
    }
    const areaOnSelect=(e)=>{
        if(e.target.value=="शहरी"){setCityHideShow(true)
        setVillageHideShow(true)}
        else if(e.target.value=="ग्रामीण"){setCityHideShow(false)
            setVillageHideShow(false)}
        else{
            setCityHideShow(false)
        setVillageHideShow(true)
        }
    }
    const perAreaOnSelect=(e)=>{
        if(e.target.value=="शहरी"){setPerCityHideShow(true)
        setPerVillageHideShow(true)}
        else if(e.target.value=="ग्रामीण"){setPerCityHideShow(false)
            setPerVillageHideShow(false)}
        else{
            setPerCityHideShow(false)
        setPerVillageHideShow(true)
        }
    }

    /*file on change */
    const handleFileChange = (e) => {
        const id=e.target.id
        const img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
          }
        if(id=="aadharPhoto"){
            setAadharImageUpload(img)
        }else{
            setCoPhotoUpload(img)
        }
        
        
      }

      if(role.state.name!=""){return(
        <>
            <Form onSubmit={submitCoordinator}>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-4" controlId="firstName">
                        <Form.Label>Name/नाव</Form.Label>
                        <Form.Control type="text" className={isEdit?"disabled":""}/>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-4" controlId="fatherName">
                        <Form.Label>Father Name/वडिलांचे नाव </Form.Label>
                        <Form.Control type="text" className={isEdit?"disabled":""}  />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-4" controlId="lastName">
                        <Form.Label>Surname/आडनाव</Form.Label>
                        <Form.Control type="text" className={isEdit?"disabled":""} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-3" controlId="dob">
                        <Form.Label>Date of Birth/जन्म तारीख </Form.Label>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            isClearable
                            onChange={birthdayChange}
                            id="dob"
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={115}
                            scrollableYearDropdown={true}
                            scrollableMonthDropdown
                            minDate={new Date("01/01/1902")}
                            maxDate={new Date()} 
                            className={isEdit?"disabled":""}
                            />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="age">
                        <Form.Label>Age/वय</Form.Label>
                        <Form.Control type="text" value={age} disabled/>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3" controlId="gender">
                        <Form.Label>Sex/लिंग </Form.Label>
                        <Form.Select aria-label="Default select example"  className={isEdit?"disabled":""}>
                            <option>-----------select-----------</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </Form.Select>
                    </Form.Group>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="education">
                        <Form.Label>Education/शिक्षण</Form.Label>
                        <Form.Control type="text"  className={isEdit?"disabled":""}/>
                    </Form.Group>
                </div>
                <div className={isEdit?"d-none":"row"}>
                <hr/>
                <Form.Label><strong>Current Address सध्याचा पत्ता</strong></Form.Label>
                </div>
                <div className={isEdit?"d-none":"row"}>
                    <Form.Group className="mb-3 col-md-3" controlId="currentAreaType" >
                        <Form.Label>Area/क्षेत्र </Form.Label>
                        <Form.Select aria-label="Default select example" className={isEdit?"disabled":""} onChange={areaOnSelect}>
                            <option>-----------select-----------</option>
                            <option value="ग्रामीण">Rural/ग्रामीण </option>
                            <option value="शहरी">Urban/शहरी</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={cityHideShow?"mb-3 col-md-3 d-bock":"mb-3 col-md-3 d-none"} controlId="currentAreaType1">
                        <Form.Label>शहरी/City </Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>-----------select-----------</option>
                            <option value="नगरपंचायत">नगरपंचायत  </option>
                            <option value="नगरपरिषद">नगरपरिषद </option>
                            <option value="महानगर पालिका">महानगर पालिका </option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3" controlId="currentAddHouse">
                        <Form.Label>House No./घर क्रमांक</Form.Label>
                        <Form.Control type="text" className={isEdit?"disabled":""}/>
                    </Form.Group>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="currentAddBulding">
                        <Form.Label>Building No./इमारत नाव,  </Form.Label>
                        <Form.Control type="text" className={isEdit?"disabled":""}/>
                    </Form.Group>
                    
                </div>
                <div className={isEdit?"d-none":"row"}>
                    <Form.Group className={isEdit?"mb-3 col-md-3 d-none":"mb-3 col-md-3"} controlId="currentAddDistrict">
                        <Form.Label>District/जिल्हा</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getTaluka}  >
                            {districtValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    districtValues.map((val,index)=>{
                                        return(
                                            <option value={val.districtNameEng}>{val.districtNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={isEdit?"mb-3 col-md-3 d-none":"mb-3 col-md-3"}  controlId="currentAddTaluka">
                        <Form.Label>Taluka/तालुका </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPinCodeAndVillage}>
                        {talukaValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    talukaValues.map((val,index)=>{
                                        return(
                                            <option value={val.blockNameMar}>{val.blockNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={isEdit?"mb-3 col-md-3 d-none":"mb-3 col-md-3"}  controlId="currentAddPin">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPostOffice}>
                        {pinCodeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    pinCodeValues.map((val,index)=>{
                                        return(
                                            <option value={val.pincode}>{val.pincode}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3 col-md-3" controlId="currentAddPost">
                        <Form.Label>post office/पोस्ट ऑफिस</Form.Label>
                        <Form.Select aria-label="Default select example">
                        {postOfficeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    postOfficeValues.map((val,index)=>{
                                        return(
                                            <option value={val.postOfficeName}>{val.postOfficeName}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group className={villageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="currentAddVillage">
                        <Form.Label>City / Village  शहर </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getGramin}>
                        {villageValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    villageValues.map((val,index)=>{
                                        return(
                                            <option value={val.villageNameEng}>{val.villageNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                   
                </div>
                <div className={isEdit?"d-none":"row"}>
                   
                
                    <Form.Group className={villageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="currentAddGram">
                        <Form.Label>ग्रामीण</Form.Label>
                        <Form.Control type="text" disabled value={gramin}/>
                    </Form.Group>
                </div>
                <div className={isEdit?"d-none":"row"}>
                    
                <Form.Group className="mb-3 col-md-3" controlId="samePermanentAddress">
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        checked={permanentAddress?"checked":""}
                        label="Permanent Address/मूळ पत्ता"
                        onChange={permanentAddressOnChange}
                    />
                </Form.Group>
                </div>
                
                <div className={permanentAddress==false?'row d-flex':'row d-none'}>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="permanentAreaType">
                        <Form.Label>Area/क्षेत्र </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={perAreaOnSelect}>
                            <option>-----------select-----------</option>
                            <option value="ग्रामीण">Rural/ग्रामीण </option>
                            <option value="शहरी">Urban/शहरी</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={perCityHideShow?"mb-3 col-md-3 d-bock":"mb-3 col-md-3 d-none"} controlId="permanentAreaType1">
                        <Form.Label>शहरी/City </Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>-----------select-----------</option>
                            <option value="नगरपंचायत">नगरपंचायत  </option>
                            <option value="नगरपरिषद">नगरपरिषद </option>
                            <option value="महानगर पालिका">महानगर पालिका </option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-3" controlId="permanentAddHouse">
                        <Form.Label>House No./घर क्रमांक</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    
                    <Form.Group className="mb-3 col-md-3" controlId="permanentAddBuilding">
                        <Form.Label>Building No./इमारत नाव,  </Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    
                </div>
                <div className={permanentAddress==false?'row d-flex':'row d-none'}>
                    <Form.Group className="mb-3 col-md-3" controlId="permanentAddDistrict">
                        <Form.Label>District/जिल्हा</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getTaluka}>
                            {districtValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    districtValues.map((val,index)=>{
                                        return(
                                            <option value={val.districtNameEng}>{val.districtNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="permanentAddTaluka">
                        <Form.Label>Taluka/तालुका </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPinCodeAndVillage}>
                        {perTalukaValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    perTalukaValues.map((val,index)=>{
                                        return(
                                            <option value={val.blockNameMar}>{val.blockNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="permanentAddPin">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getPostOffice}>
                        {perPinCodeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    perPinCodeValues.map((val,index)=>{
                                        return(
                                            <option value={val.pincode}>{val.pincode}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3 col-md-3" controlId="permanentAddPost">
                        <Form.Label>post office/पोस्ट ऑफिस</Form.Label>
                        <Form.Select aria-label="Default select example">
                        {perPostOfficeValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    perPostOfficeValues.map((val,index)=>{
                                        return(
                                            <option value={val.postOfficeName}>{val.postOfficeName}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group className={perVillageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="permanentAddVillage">
                        <Form.Label>City / Village  शहर </Form.Label>
                        <Form.Select aria-label="Default select example" onChange={getGramin}>
                        {perVillageValues==null?(<option>-----------select-----------</option>):<><option>-----------select-----------</option>
                                {
                                    
                                    perVillageValues.map((val,index)=>{
                                        return(
                                            <option value={val.villageNameEng}>{val.villageNameMar}</option>
                                        )
                                    })
                                }
                            </>}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className={permanentAddress==false?'row d-flex':'row d-none'}>
                
                    <Form.Group className={perVillageHideShow?"mb-3 col-md-3 d-none":"mb-3 col-md-3 d-block"} controlId="permanentAddGram">
                        <Form.Label>ग्रामीण</Form.Label>
                        <Form.Control type="text" disabled value={perGramin}/>
                    </Form.Group> 
                </div>
                <div className={isEdit?"d-none":"row"}>
                    <hr/>
                    <Form.Group className="mb-3 col-md-3" controlId="currentBusiness">
                        <Form.Label>Current Business/सध्याचा व्यवसाय</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="officeAddress">
                        <Form.Label>Office Address/कार्यालय पत्ता</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <hr/>
                    <Form.Label><strong>Contact/संपर्क</strong></Form.Label>
                </div>
                <div className='row'>
                <Form.Group className="mb-3 col-md-3" controlId="mobileNo">
                        <Form.Label>Mobile/मोबाईल</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="whatsAppNo">
                        <Form.Label>Whatsapp/व्हॉट्सॲप</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="email">
                        <Form.Label>Email Id/इमेल आयडी </Form.Label>
                        <Form.Control type="email" />
                    </Form.Group>
                </div>
                <div className={isEdit?"d-none":"row"}>
                    <hr/>
                    <Form.Label><strong>File Upload</strong></Form.Label>
                </div>
                <div className={isEdit?"d-none":"row"}>
                
                    <Form.Group className="mb-3 col-md-3" controlId="aadharNo">
                            <Form.Label>Aadhar No./आधार क्रमांक  </Form.Label>
                            <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group controlId="aadharPhoto" className="mb-3 col-md-3">
                        <Form.Label>Upload Aadhar/आधार अपलोड</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange}/>
                    </Form.Group>
                    <Form.Group controlId="coPhoto" className="mb-3 col-md-3">
                        <Form.Label>Upload Photo/फोटो  अपलोड</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange}/>
                    </Form.Group>
                </div>
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </>
    )}
    
}

export default AddCoordinatorComponent;