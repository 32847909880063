import { useEffect } from 'react';
import img from '../asset/Ashwazep Logo-03.png'
import { Link } from 'react-router-dom';

const CommingSoonComponent=()=>{
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    return(
        <>
            <div className='row commingSoonContainer'>
                <div className='commingSoonImage '>
                    {/* <img src={img}/> */}
                    <div className='pt-5'>
                        <h1><strong>लवकरच येत आहोत....!!!</strong></h1><br/><br/>
                        <h4>हे पृष्ठ सध्या निर्माणाधीन आहे. कृपया काही दिवसांनी परत तपासा. जर आम्ही तुम्हाला विशिष्ट प्रश्नासाठी मदत करू शकलो तर कृपया आम्हाला <Link to="https://ashwazep@gmail.com" className='red-text'>ashwazep@gmail.com</Link> वर ईमेल पाठवा</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommingSoonComponent;