
import img4 from '../../asset/mahaBackground.jpg'

const MahamandalYojnaShimmerComponent=()=>{
    return(
        <>
            <img src={img4}/>
        </>
    )
}

const style={
    "height":"200px"
}
export default MahamandalYojnaShimmerComponent;