export const Menu=[
    {
        Menu_Id: "MM1",
        Menu_Name: "प्रशिक्षण कार्यक्रम",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM1-SM1",
                Menu_Name: "उद्योजकीय मानसिकता प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM2",
                Menu_Name: "सामाजिक संस्था व्यवस्थापन प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM3",
                Menu_Name: "सामाजिक कार्यकर्ता प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM4",
                Menu_Name: "गृहनिर्माण सहकारी संस्था व्यवस्थापन प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM5",
                Menu_Name: "व्यक्तीमत्व विकास प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM6",
                Menu_Name: "नेतृत्व विकास प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM7",
                Menu_Name: "उद्योग व्यवसाय प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM1-SM8",
                Menu_Name: "रोगानुसार योगा प्रशिक्षण",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            }
        ]
    },
    {
        Menu_Id: "MM2",
        Menu_Name: "परवाने, नोंदणी अर्ज ",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM2-SM1",
                Menu_Name: "परवाने व नोंदणी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: [
                    {
                        Menu_Id: "MM2-SM1-CM1",
                        Menu_Name: "MSME उद्यम आधार नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM2",
                        Menu_Name: "दुकान व आस्थापना परवाना",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    // {
                    //     Menu_Id: "MM2-SM1-CM3",
                    //     Menu_Name: "व्यापार परवाना",
                    //     Menu_Role: "0",
                    //     Menu_Position: "Left"
                    // },
                    {
                        Menu_Id: "MM2-SM1-CM4",
                        Menu_Name: "FSSAI अन्न परवाना",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM5",
                        Menu_Name: "जीएसटी नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM6",
                        Menu_Name: "पॅन कार्ड",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM7",
                        Menu_Name: "भागीदारी फर्म नोंदणी.",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM8",
                        Menu_Name: "कंपनी नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM9",
                        Menu_Name: "PROFESSINOL TAX",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM90",
                        Menu_Name: "EPFO",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM91",
                        Menu_Name: "ESIC",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM92",
                        Menu_Name: "ट्रेडमार्क नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM93",
                        Menu_Name: "पेटंट नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM94",
                        Menu_Name: "कॉपीराइट नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM1-CM95",
                        Menu_Name: "आयएसओ नोंदणी",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    }
                ]
            },
            {
                Menu_Id: "MM2-SM2",
                Menu_Name: "कर परतावे",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: [
                    {
                        Menu_Id: "MM2-SM2-CM1",
                        Menu_Name: "जीएसटी परतावे",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM2-CM2",
                        Menu_Name: "आयकर परतावे",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM2-CM3",
                        Menu_Name: "T.D.S परतावे",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    },
                    {
                        Menu_Id: "MM2-SM2-CM4",
                        Menu_Name: "व्यावसायिक कर परतावे",
                        Menu_Role: "0",
                        Menu_Position: "Left"
                    }
                ]
            }
        ]
    },
    {
        Menu_Id: "MM3",
        Menu_Name: "कर्ज व अनुदान",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM3-SM1",
                Menu_Name: "कर्ज प्रस्ताव व अनुदान प्रस्ताव",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM3-SM2",
                Menu_Name: "उद्योग व्यवसाय प्रकल्प अहवाल PROJECT REPORT",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            }
        ]
    },
    {
        Menu_Id: "MM4",
        Menu_Name: "स्वयंसेवी संस्था ",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM4-SM1",
                Menu_Name: "स्वयंसेवी संस्था नोंदणी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            // {
            //     Menu_Id: "MM4-SM2",
            //     Menu_Name: "सोसायटी आणि ट्रस्ट नोंदणी",
            //     Menu_Role: "0",
            //     Menu_Position: "Left",
            //     children: []
            // },
            {
                Menu_Id: "MM4-SM3",
                Menu_Name: "निती आयोग नोंदणी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM4",
                Menu_Name: "12 ए आणि 80 जी नोंदणी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM5",
                Menu_Name: "एफसीआरए नोंदणी FCRA",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM6",
                Menu_Name: "व्यवसाईक सामाजिक जबाबदारी CSR",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM7",
                Menu_Name: "निधी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM8",
                Menu_Name: "प्रकल्प प्रस्ताव",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM9",
                Menu_Name: "इतिवृत्त लेखन",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM90",
                Menu_Name: "वार्षिक अहवाल",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM91",
                Menu_Name: "आर्थिक विवरणपत्र",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM4-SM92",
                Menu_Name: "बदल अहवाल",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            }
        ]
    },
    {
        Menu_Id: "MM5",
        Menu_Name: "सहकारी संस्था ",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM5-SM1",
                Menu_Name: "सहकारी गृहनिर्माण संस्था नोंदणी",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            // {
            //     Menu_Id: "MM5-SM2",
            //     Menu_Name: "गृहनिर्माण  सोसायटी चे  मानीव अभिहस्तांतरण (Deemed Conveyance)",
            //     Menu_Role: "0",
            //     Menu_Position: "Left",
            //     children: []
            // }
        ]
    },
    {
        Menu_Id: "MM6",
        Menu_Name: "उद्योग,  व्यवसाय",
        Menu_Role: "0",
        Menu_Position: "Left",
        children: [
            {
                Menu_Id: "MM6-SM1",
                Menu_Name: "लघु उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM6-SM2",
                Menu_Name: "गृह उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM6-SM3",
                Menu_Name: "आधुनिक उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM6-SM4",
                Menu_Name: "शेतीपूरक उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM6-SM5",
                Menu_Name: "अन्न प्रक्रिया उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            },
            {
                Menu_Id: "MM6-SM6",
                Menu_Name: "सेवा उद्योग",
                Menu_Role: "0",
                Menu_Position: "Left",
                children: []
            }
        ]
    }
    
]