import { Link, NavLink } from 'react-router-dom';
import img from '../asset/Ashwazep Logo_Artboard 2.png'
import { Facebook,Instagram,Youtube,Linkedin,EnvelopeAt, PersonGear,Whatsapp } from 'react-bootstrap-icons'
import { useEffect } from 'react';
import { useContext } from 'react';
import loginContext from '../context/Login/LoginContext';
import 'bootstrap/js/dist/collapse'

const HeaderComponent=()=>{
    const a=useContext(loginContext)
    const logout=()=>{
        sessionStorage.removeItem("user");
        a.update("")
    }
    return(
        <header>
        <section id="scroll_bar" className='topBorder'>
            <div className="container-xxl ">
                <div className="row">
                    <div className="col-12 d-flex">
                        <marquee behavior="scroll" direction="left" scrollamount="6" >
    
                        
                        
                        <span className="mx-5" id="adjinfo"></span>
                            <span className="mx-5"></span>
                            <span className="mx-5">&nbsp;&nbsp;</span>
                        </marquee> 
    
                        <button className="btn btn-sm text-nowrap d-none" data-bs-toggle="modal" data-bs-target="#click">इथे क्लिक करा</button>
    
    
                    </div>
                </div>
            </div>
        </section>
		<div className="container-xxl p-2">
			<div className="row">
                <div className="col-xl-3 pt-3 mt-2">
                    <div className="row">
                       <ul className='d-flex list-unstyled social'>
                            <li><Link to="https://www.facebook.com/profile.php?id=61565050739727&mibextid=ZbWKwL" target='_blank'><Facebook  color="royalblue" size={25}/></Link></li>
                            <li><Link to="https://www.instagram.com/ashwazep?igsh=MWpxcGs2Y3VnemQwZw=="  target='_blank'><Instagram color="#bc2a8d" size={25}/></Link></li>
                            <li><Link to="https://www.youtube.com/@ashwazep.online" target='_blank'><Youtube color="red" size={25}/></Link></li>
                            <li><Link to="https://ashwazep@gmail.com" target='_blank'><EnvelopeAt color="#0077b5" size={25}/></Link></li>
                            <li>
                            <Link to="https://wa.me/918421134990" className="me-4 text-reset" target='_blank'>
                                <Whatsapp color="green" size={25}/>
                            </Link>
                            </li>
                       </ul>
                    </div>
                </div>
				<div className="col-xl-6 my-auto" >
					<div className=" text-center">
						<div className="logo">
							<Link to="/"><img src={img} alt=""/></Link>
                            
						</div>
					</div>
				</div>
                <div className="col-xl-3 pt-3  mt-2">
                    <div className="row">
                        <div className="col-md-5 text-center">
                        <NavLink to="/aboutUs">आमच्या विषयी</NavLink>
                        </div>
                        <div className="col-md-3 text-center">
                        <NavLink to="/contactUs">संपर्क</NavLink>
                        </div>
                        {
                            a.state.name==""?<><div className="col-md-4 text-center" id="sessionLogin">
                            <NavLink to="/login">नोंदणी (लॉगिन )</NavLink>
                        </div></>:<>
                        <div className="col-md-3 text-center" id="sessionLogin">
                            <NavLink  data-bs-toggle="collapse" className="collapsed" to="#profileDropdown"><PersonGear color='black' size={25}/></NavLink>
                            <ul id='profileDropdown' className='profileDropdown collapse'>
                                {/* <li><Link href="#test">Profile</Link></li> */}
                                <li><Link  onClick={logout}>Logout</Link></li>
                            </ul>
                        </div>
                        </>
                        }
                        
                        
                        <div className="col-md-4 d-none" id="sessionLogedIn">
                            <a href="#test">adw</a>
                            <ul >
                                <li><a href="#test">Profile</a></li>
                                <li><a  href="#test" id="logout">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
			</div>
		</div>
        <section id="scroll_bar">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-12 d-flex">
                    <button className="btn btn-sm text-nowrap text-white" data-bs-toggle="modal" data-bs-target="#click">माहितीसाठी !!</button>
                        <marquee behavior="scroll" direction="left" scrollamount="6" className="pt-1">
    
                        
                        
                        <span className="mx-5" id="adjinfo">विविध विषयांवरील प्रशिक्षण कार्यकम ऑनलाईन पद्धतीने लवकरच आपल्या साठी आम्ही घेऊन येत आहोत. उमेदवारांनी आपली नोंदणी करून घ्यावी. वेळोवेळी माहिती दिली जाईल.</span>
                        </marquee> 
    
                        <button className="btn btn-sm text-nowrap d-none" data-bs-toggle="modal" data-bs-target="#click">इथे क्लिक करा</button>
    
    
                    </div>
                </div>
            </div>
        </section>
	</header>
    )
}

export default HeaderComponent