import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrls } from "../../util/apiUrl";
import { Link } from "react-router-dom"


const DashboardUserCoordinatorDetailComponent=()=>{
    const param=useParams()
    const [coDetails,setCoDetails]=useState("")
    useEffect(()=>{
        fetchData()
    },[])
    const fetchData=async()=>{
        const data=await fetch(apiUrls.getDetailsOfCoordinator,{
            method:'POST',
            body:JSON.stringify({"id":param.id}),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
        }) 
        const json=await data.json()
        setCoDetails(json[0])

    }
    const print=()=>{
        var content = document.getElementById("divcontents");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    return(
        <>
        <div >
        <h2 className="text-center">Co-Ordinator {coDetails.firstName} </h2><br/><br/>
            <div className="row">
                <div className="col-md-2">
                    <img src={coDetails.coPhoto} style={img}></img>
                </div>
                <div className="col-md-4">
                    <p><strong>Name :</strong> {coDetails.firstName+" "+coDetails.fatherName+" "+coDetails.lastName}</p>
                    <p><strong>DOB : </strong>{coDetails.dob} </p>
                    <p><strong>Age : </strong>{coDetails.age} <label><strong>Gender :</strong> {coDetails.gender}</label></p>
                </div>
                <div className="col-md-4">
                    <p><strong>Mobile Number : </strong>{coDetails.mobileNo} </p>
                    <p><strong>Whatsapp Number : </strong>{coDetails.whatsAppNo}</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6">
                    <p><strong>Current Address :</strong> {coDetails.currentAddHouse+","+coDetails.currentAddBulding+","+coDetails.currentAddDistrict+","+coDetails.currentAddTaluka+","+coDetails.currentAddVillage+","+coDetails.currentAddGram}</p>
                    <p><strong>Area :</strong>{coDetails.currentAreaType}</p>
                    <p><strong>Pincode :</strong>{coDetails.currentAddPin}</p>
                </div>
                <div className="col-md-6">
                    <p><strong>Permanent Address : </strong>{coDetails.permanentAddHouse+","+coDetails.permanentAddBuilding+","+coDetails.permanentAddDistrict+","+coDetails.permanentAddTaluka+","+coDetails.permanentAddVillage+","+coDetails.permanentAddGram}</p>
                    <p><strong>Area :</strong>{coDetails.permanentAreaType}</p>
                    <p><strong>Pincode :</strong>{coDetails.permanentAddPin}</p>
                </div>
            </div>
        </div>
            <div id="divcontents" className="printTable d-none">
                <table border="0">
                    <tr>
                        <td><img src={coDetails.coPhoto} style={img}></img></td>
                        <table>
                            <tr>
                                <td>Name:</td>
                                <td>{coDetails.firstName+" "+coDetails.fatherName+" "+coDetails.lastName}</td><td>&nbsp;</td><td>&nbsp;</td>
                                <td>Mobile Number : </td>
                                <td>{coDetails.mobileNo} </td>
                            </tr>
                            <tr>
                                <td>DOB : </td>
                                <td>{coDetails.dob}</td><td>&nbsp;</td><td>&nbsp;</td>
                                <td>Whatsapp Number :  </td>
                                <td>{coDetails.whatsAppNo}</td>
                            </tr>
                            <tr>
                                <td>Age :</td>
                                <td>{coDetails.age}</td><td>&nbsp;</td><td>&nbsp;</td>
                                <td>Gender :</td>
                                <td>{coDetails.gender}</td>
                            </tr>
                        </table>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td>Current Address :</td>
                        <td>{coDetails.currentAddHouse+","+coDetails.currentAddBulding+","+coDetails.currentAddDistrict+","+coDetails.currentAddTaluka+","+coDetails.currentAddVillage+","+coDetails.currentAddGram}</td>
                        <td>&nbsp;</td><td>&nbsp;</td>
                        <td>Permanent Address : </td>
                        <td>{coDetails.permanentAddHouse+","+coDetails.permanentAddBuilding+","+coDetails.permanentAddDistrict+","+coDetails.permanentAddTaluka+","+coDetails.permanentAddVillage+","+coDetails.permanentAddGram}</td>
                    </tr>
                    <tr>
                        <td>Area :</td>
                        <td>{coDetails.currentAreaType}</td>
                        <td>&nbsp;</td><td>&nbsp;</td>
                        <td>Area : </td>
                        <td>{coDetails.permanentAreaType}</td>
                    </tr>
                    <tr>
                        <td>Pincode :</td>
                        <td>{coDetails.currentAddPin}</td>
                        <td>&nbsp;</td><td>&nbsp;</td>
                        <td>pincode : </td>
                        <td>{coDetails.permanentAddPin}</td>
                    </tr>
                </table>
            </div>
            <iframe id="ifmcontentstoprint" className="printFrame d-none"></iframe>
            <div class="text-center mt-5">
                <Link to="#" id="next" className="ashBtn" onClick={print}>छापा</Link>
            </div>
        </>
    )
}

const img={"width":"100%"}

export default DashboardUserCoordinatorDetailComponent;