import { NavLink } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import loginContext from "../../context/Login/LoginContext"
import { apiUrls } from "../../util/apiUrl"


const DashboardIndexxComponent=()=>{
    const role=useContext(loginContext)
    const [coordinatorCount,setCoordinatorCount]=useState("")
    const [userCount,setUserCount]=useState("")

    useEffect(()=>{
        fetchCoordinatorCount()
        fetchUserCount()
    },[])

    const fetchUserCount=async()=>{
        const data=await fetch(apiUrls.userCount)
        const json=await data.json();
        console.log(json)
        setUserCount(json.count)
    }
    const fetchCoordinatorCount=async()=>{
        const data=await fetch(apiUrls.coordinatorCount)
        const json=await data.json();
        console.log(json)
        setCoordinatorCount(json.count)
    }

    return(
        <>
            <div>
                <div className="row">
                {role.state.name=="admin"?<>
                    <div className="col-md-4" >
                        <NavLink to="/viewCoordinator">
                                <div className="card count countWarning">
                                    <h3>Total Coordinator</h3>
                                    <h4>{coordinatorCount}</h4>
                                </div>
                        </NavLink>
                    </div>
                    
                    </>:<></>
                }
                    {role.state.name=="Coordinator" || role.state.name=="admin"?<div className="col-md-4">
                        <NavLink to="/viewUser">
                                <div className="card count countWarning">
                                    <h3>Total Users</h3>
                                    <h4>{userCount}</h4>
                                </div>
                        </NavLink>
                    </div>:<></>}
                    
                    <div className="col-md-4">
                        <NavLink to="/viewNews">
                                <div className="card count countWarning">
                                    <h3>View News</h3>
                                </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardIndexxComponent