import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import DetailPage from './components/DetailPage';
import LandingComponent from './components/LandingComponent';
import ApiCall from './components/ApiCall';
import LoginCoponent from './components/LoginComponent';
import ErrorPageComponent from './components/errorComponents/ErrorPageComponent'
import MahamandaleYojanaComponent from './components/MahamandaleYojanaComponent'
import DistrictWiseComponent from './components/DistrictWiseComponent'
import CommingSoonComponent from './components/CommingSoonComponent';
import AboutUsComponent from './components/AboutUsComponent';
import CoordinatorComponent from './components/CoordinatorComponent';
import AddCoordinatorComponent from './components/AddCoordinatorComponent'
import DashboardIndexxComponent from './components/dashboardComponents/DashboardIndexxComponent';
import DashboardViewCoordinator from './components/dashboardComponents/DashboardViewCoordinator';
import AddUserComponent from './components/AddUserComponent';
import DashboardViewUser from './components/dashboardComponents/DashboardViewUser';
import WebsiteQuotationComponent from './components/WebsiteQuotationComponent';
import DashboardUserCoordinatorDetailComponent from './components/dashboardComponents/DshboardUserCoordinatorDetailComponent';
import DashboardUserDetailComponent from './components/dashboardComponents/DshboardUserDetailComponent'
import DashbordViewNews from './components/dashboardComponents/DashboardViewNews';
import DashboardAddNews from './components/dashboardComponents/DashboardAddNews';
import AddCoordinatorWorkArea from './components/AddCoordinatorWorkArea';
import ContactUs from './components/contactUs';



const appRouter=createBrowserRouter([
  {
    path:"/",
    element:<App/>,
    children:[
      {
        path:"/",
        element:<LandingComponent/>
      },
      {
        path:"/details/:id",
        element:<DetailPage/>
      },
      {
        path:"/apicall",
        element:<ApiCall/>
      },
      {
        path:"/login",
        element:<LoginCoponent/>
      },
      {
        path:"/mahamandaleYojana",
        element:<MahamandaleYojanaComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/districtWise",
        element:<DistrictWiseComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/commigsoon",
        element:<CommingSoonComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/aboutUs",
        element:<AboutUsComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/coordinator",
        element:<CoordinatorComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/addCoordinator",
        element:<AddCoordinatorComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/dashboard",
        element:<DashboardIndexxComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/viewCoordinator",
        element:<DashboardViewCoordinator/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/addUser",
        element:<AddUserComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/viewUser",
        element:<DashboardViewUser/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/websiteQuotation",
        element:<WebsiteQuotationComponent/>
      },
      {
        path:"/dashboardDetails/:id",
        element:<DashboardUserCoordinatorDetailComponent/>
      },
      {
        path:"/dashboardDetailsUser/:id",
        element:<DashboardUserDetailComponent/>
      }
      ,
      {
        path:"/addCoordinator/:id",
        element:<AddCoordinatorComponent/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/viewNews",
        element:<DashbordViewNews/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/addNews",
        element:<DashboardAddNews/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/coordinatorWorkArea/:id",
        element:<AddCoordinatorWorkArea/>,
        errorElement:<ErrorPageComponent />
      },
      {
        path:"/contactUs",
        element:<ContactUs/>
      },{
        path:"/addNews/:id",
        element:<DashboardAddNews/>,
        errorElement:<ErrorPageComponent />
      },
    ]
  }
  
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
